import React from 'react'
import Layout from '../components/layout'
import Map from '../components/Map/Map'
import LiveTime from '../components/Time'
import { Helmet } from 'react-helmet'
import Distributors from '../components/Distributors/Distributors'

const contacts = () => {
  return (
    <Layout>
      <Helmet>
        <title>IN-ECO Contacts</title>
      </Helmet>
      <div className="header-contacts">
        <div className="columns">
          <div className="column contact-info-column">
            <h2 className="title title-bordered">Lets work together!</h2>
            <h3 className="title title-small">
              Our office and warehouse are conveniently located in Kaunas,
              Lithuania. Contact us by any means!
            </h3>
            <div className="columns contact-info">
              <div className="column">
                <h2 className="title title-middle title-bordered">
                  GET IN TOUCH
                </h2>
                <div className="content-text content-row">
                  <span>Email </span>
                  <span>info@in-eco.biz</span>
                </div>
                <div className="content-text content-row">
                  <span className="content-title">Tel.</span>+370 37 21 42 25
                </div>
                <div className="content-text content-row">
                  <span className="content-title">Fax</span>+370 37 21 42 26
                </div>
                <div className="content-text content-text-offset">
                  Ašigalio g. 6 II korpusas <br /> Kaunas, LT-49142 <br />{' '}
                  Lithuania
                </div>
              </div>
              <div className="column">
                <h2 className="title title-middle title-bordered">
                  OPENING HOURS
                </h2>
                <div className="content-text content-row">
                  <span className="content-title">I-IV</span>8:00 - 17:00
                </div>
                <div className="content-text content-row">
                  <span className="content-title">V</span>8:00 - 16:00
                </div>
                <div className="content-text content-row">
                  <span className="content-title">Lunch break</span>12:00 -
                  13:00
                </div>
                <h3 className="content-text content-text-offset text-accented">
                  <span className="content-title">Local time:</span>
                  <LiveTime />
                </h3>
              </div>
            </div>
          </div>
          <Distributors
            title="Our distributors"
            czechRepublic="Czech Republic"
            latvia="Latvia"
            poland="Poland"
            ukraine="Ukraine"
            macedonia="Macedonia"
            uzbekistan="Uzbekistan"
            kazakhstan="Kazakhstan"
            serbia="Serbia"
          />
        </div>
      </div>
      <Map />
    </Layout>
  )
}

export default contacts
